import type { PermissionRequest } from '@apps/Application/Application.types'
import type { IInputs, SubmitAction } from '@apps/Application/widgets/AppSettingsForm/appSettingsForm.types'
import { clientApi as api } from '@common/api'
import type { IRejectedPayloadType } from '@redux/slices/applicationList/applicationList.types'
import type { AxiosError } from 'axios'


export interface IApiError {
  detail?: IRejectedPayloadType['detail'],
  status: number | boolean,
}

const submitHandler: (
  data: { fields: IInputs, appId: string, slot: number, latestAppVersionId?: string, appVersionId?: string },
  params: { replaceSlot?: boolean, submitAction?: SubmitAction, preventWindowClose?: boolean },
  form: {
    setError: (statusCode: IApiError) => void,
    clearErrors: () => void,
    onSuccess: () => void,
  },
) => Promise<void> = async (data, params = {}, form) => {
  const { fields, appId, slot, latestAppVersionId } = data
  const { replaceSlot, submitAction, preventWindowClose } = params
  const permissions: PermissionRequest = {
    videoPanel: fields.videoPanel as boolean,
    rewriteMessages: fields.rewriteMessages as boolean,
    tipOptions: fields.tipOptions as boolean,
  }
  let settings = fields
  delete settings.videoPanel
  delete settings.rewriteMessages
  delete settings.tipOptions

  settings = Object.entries(settings)
    .reduce((obj, item) => {
      const [key, value] = item as [string, unknown]
      if(value !== null) {
        obj[key] = value ?? ''
      }
      return obj
    }, {} as { [key: string]: unknown })

  const req: IRequestAppInstall = {
    appId,
    slot,
    permissions,
    settings,
    replaceSlot,
    latestAppVersionId,
    app_version_id: data.appVersionId,
  }

  try {
    await api.post<unknown>(`/api/apps/${submitAction || ''}`, req)
    form.clearErrors()
    if(!preventWindowClose) {
      window.parent?.close()
    }
    form.onSuccess()
  } catch (e) {
    const err = e as AxiosError
    const data = err.response?.data as IRejectedPayloadType
    const apiError = {
      detail: data?.detail,
      status: err.response?.status || true,
    }
    form.setError(apiError)
  }
}

export default submitHandler
