import cx from 'classnames'
import isEqual from 'lodash/isEqual'
import type { Ref } from 'react'
import { useCallback, useMemo, useState } from 'react'
import type { Props, SelectInstance } from 'react-select'
import Select from 'react-select'

import ControlSelect from './Control'
import DropdownIndicator from './DropdownIndicator'
import IndicatorsContainer from './IndicatorsContainer'
import Menu from './Menu'
import MenuList from './MenuList'
import Option from './Option'
import Placeholder from './Placeholder'
import SingleValue from './SingleValue'
import classNames from './styles.module.scss'
import { addDataAcceptance, getOptionLabelForSelect, GetOptionValueForSelect } from './utils'


interface IProps {
  options: IOption[] | undefined,
  name: string,
  className?: string,
  classNameControl?: string,
  classNameIndicator?: string,
  classNameOption?: string,
  isSearchable?: boolean,
  isClearable?: boolean,
  onChange: (value: unknown) => void,
  value: string | undefined,
  inputRef?: Ref<SelectInstance>,
  error: boolean,
  isLoading?: boolean,
}

function SelectInput({ options, name, className, value, onChange, inputRef, error, isLoading, onMenuOpen, onMenuClose, ...props }: IProps & Props): JSX.Element {
  const generateValue = useCallback((e: unknown) => {
    if(options === undefined) { return }
    return options.find(i => {
      const optionValue = i.value as unknown
      return isEqual(optionValue, e)
    }) || null
  }, [options, value])

  const handleChange = useCallback((e: any) => {
    const newValue = e as IOption
    if(e === null) {
      return onChange(undefined)
    }
    return onChange(newValue.value)
  }, [value, onChange])

  const selectComponents = useMemo(() => {
    // eslint-disable-next-line indent, @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line react/prop-types
    const prefix = props['data-test'] as string
    return {
      IndicatorSeparator: null,
      SingleValue,
      DropdownIndicator: addDataAcceptance(DropdownIndicator, prefix + '-indicator') as typeof DropdownIndicator,
      IndicatorsContainer,
      MenuList: addDataAcceptance(MenuList, prefix + '-menu-list'),
      Option: addDataAcceptance(Option, prefix + '-option'),
      Control: addDataAcceptance(ControlSelect, prefix + '-contol'),
      Menu: addDataAcceptance(Menu, prefix + '-menu'),
      Placeholder: addDataAcceptance(Placeholder, prefix + '-placeholder'),
    }
  }, [])

  const [menuIsOpen, setMenuStatus] = useState(false)
  return (
    <>
      <Select
        {...props}
        className={cx(
          classNames.select,
          className,
          menuIsOpen && classNames.open,
          error && classNames.error,
        )}
        id={name}
        instanceId={name}
        value={generateValue(value)}
        options={options}
        getOptionLabel={getOptionLabelForSelect}
        getOptionValue={GetOptionValueForSelect}
        onChange={handleChange}
        // eslint-disable-next-line indent, @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line react/prop-types
        components={selectComponents}
        ref={inputRef as undefined}
        onMenuOpen={() => {
          setMenuStatus(true)
          onMenuOpen && onMenuOpen()
        }}
        onMenuClose={() => {
          setMenuStatus(false)
          onMenuClose && onMenuClose()
        }}
        isLoading={isLoading}
      />
    </>
  )
}

SelectInput.defaultProps = {
  rules: {},
  className: undefined,
  classNameControl: '',
  classNameIndicator: '',
  classNameOption: '',
  classNameSingleValue: '',
  isSearchable: false,
  isClearable: false,
  inputRef: undefined,
  isLoading: false,
}

export default SelectInput
