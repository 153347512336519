import type { MenuProps } from 'react-select'
import { components } from 'react-select'

import classNames from './styles.module.scss'


export default function Menu(props: MenuProps): JSX.Element {
  return (
    <components.Menu {...props} className={classNames.menu} />
  )
}
