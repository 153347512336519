import { emptyApplicationsArray } from '@common/constants'
import { useAppSelector, useTranslate } from '@common/hooks'
import cx from 'classnames'
import { useMemo } from 'react'

import classNames from './styles.module.scss'


interface IProps {
  onClick: (slot: number) => void,
  slotSelected?: number,
}

UserSlots.defaultProps = {
  slotSelected: undefined,
}

export default function UserSlots({
  onClick,
  slotSelected,
}: IProps): JSX.Element {
  const userSlots = useAppSelector(store => store.userSlots)
  const { translate, interpolate } = useTranslate()

  const renderedSlot = useMemo(() => {
    const apps = userSlots?.data?.items || emptyApplicationsArray
    const slots = [...Array(10).keys()]
      .map((ind) => {
        const app = apps.find(app => app.slot === ind + 1)
        return (
          <div className={classNames.option} key={ind} onClick={() => onClick(ind + 1)}>
            <span className={cx(classNames.slotId, slotSelected === ind + 1 && classNames.selected)} data-test={`slot-number-${ind}`}>
              {interpolate(translate('Slot %s'), [ind + 1])}
            </span>
            {app && <span className={classNames.appName} data-test={`slot-value-${ind}`}>{app.name}</span >}
            {!app && <span className={classNames.emptyApp} data-test={`slot-value-${ind}`}>{translate('None selected')}</span>}
          </div>
        )
      })
    return slots
  }, [userSlots])

  return (
    <>
      {renderedSlot}
    </>
  )
}
