import cx from 'classnames'
import type { ChangeEventHandler, InputHTMLAttributes, LegacyRef } from 'react'
import { useCallback } from 'react'

import classNames from './styles.module.scss'


interface IProps {
  checkboxLabel: string,
  className?: string,
  tabIndex?: number,
  inputRef: LegacyRef<HTMLInputElement> | undefined,
  onChange: (value: boolean) => void,
  error: boolean,
}

CheckboxInput.defaultProps = {
  className: '',
}

function CheckboxInput({ className, value, onChange, checkboxLabel, disabled, tabIndex, inputRef, error, ...props }: IProps & InputHTMLAttributes<HTMLInputElement>): JSX.Element {
  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    if(e.target) {
      onChange(e.target.checked)
    }
  }, [onChange])

  return (
    <label
      className={cx(classNames.label, disabled && classNames.disabled, classNames, error && classNames.error, className)}
    >
      <input
        {...props}
        type="checkbox"
        onChange={handleChange}
        className={classNames.input}
        ref={inputRef}
        checked={Boolean(value) === true}
        tabIndex={tabIndex}
      />
      <span className={classNames.icon}/>
      {checkboxLabel}
    </label>
  )
}

CheckboxInput.defaultProps = {
  rules: {},
  checkboxLabel: '',
  tabIndex: undefined,
}

export default CheckboxInput
