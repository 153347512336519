import type { ReactElement } from 'react'
import type { GetOptionLabel, GetOptionValue } from 'react-select'

import type { SelectProps } from './types'


export const getOptionLabelForSelect: GetOptionLabel<unknown> = (opt) => {
  const option = opt as { [key: string]: any }
  return option['label' as keyof IOption]
}

export const GetOptionValueForSelect: GetOptionValue<unknown> = (opt) => {
  const option = opt as { [key: string]: any }
  return option['value' as keyof IOption] as string
}

type DataFunc<T> = (Component: T, dataTEst: string) => (props: SelectProps) => ReactElement<any, any>

export const addDataAcceptance: DataFunc<any> = (Component, dataTest) =>
  props => (
    <Component
      { ...props }
      // eslint-disable-next-line react/prop-types, @typescript-eslint/no-unsafe-assignment
      innerProps={ Object.assign({}, props.innerProps, { 'data-test': dataTest }) }
    />
  )
