type IKeyMap = {
  [key in PermissionKey]: PermissionLabel
}

type UsedPermissions = {
  [key in PermissionKey]?: string
}

function getPermissionLabel(key: PermissionKey): PermissionLabel {
  const keyMap: IKeyMap = {
    videoPanel: 'Broadcast panel',
    rewriteMessages: 'Transform messages',
    tipOptions: 'Tip options',
  }

  return keyMap[key]
}

const singleUsePermissions: PermissionKey[] = ['tipOptions', 'videoPanel']

export default function getPermissions(application: IApplication | null, slots: IApplication[]): IPermissionKeyed[] {
  if(!application) { return [] }
  const { permissionsValues } = application
  const permissions = application?.latest?.permissions || application.permissions
  if(!permissions) { return [] }

  const permissionIsUsed: UsedPermissions = singleUsePermissions
    .reduce((obj, item) => {
      const app = slots.find(s => s.permissionsValues?.[item])
      obj[item] = application.name === app?.name ? undefined : app?.name
      return obj
    }, {} as UsedPermissions)

  return Object
    .keys(permissions)
    .filter(p => permissions[p as PermissionKey].settings === 'ask')
    .map((p) => ({
      key: p as PermissionKey,
      value: !application.isInstalled || ((permissionsValues && permissionsValues[p as PermissionKey]) ?? true),
      label: getPermissionLabel(p as PermissionKey),
      showWarningSingleApp: singleUsePermissions.includes(p as PermissionKey),
      showWarningAlreadyUsed: permissionIsUsed[p as PermissionKey],
      showWarningActiveAppOverride: p === 'videoPanel',
    }))
}
