import cx from 'classnames'

import classNames from './styles.module.scss'


interface IProps {
  text: string,
  dataTest?: string,
  className?: string,
}

EmptyBlock.defaultProps = {
  dataTest: undefined,
  className: '',
}

export default function EmptyBlock({
  text,
  className,
}: IProps): JSX.Element {
  return (
    <div className={cx(classNames.wrapper, className)} data-test="empty-block">
      {text}
    </div>
  )
}
