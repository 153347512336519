import cx from 'classnames'
import type { ChangeEvent, InputHTMLAttributes, LegacyRef } from 'react'
import { useCallback } from 'react'

import styles from './styles.module.scss'


interface IProps extends InputHTMLAttributes<HTMLInputElement>{
  value: string,
  inputRef?: LegacyRef<HTMLInputElement> | undefined,
  error?: boolean,
  onChange: (value: unknown) => void,
}

Input.defaultProps = {
  inputRef: undefined,
  error: false,
}

function Input({ value, onChange, inputRef, error, ...props }: IProps): JSX.Element {
  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if(!e.target.value) {
      const emptyVal = props.type === 'number' ? null : ''
      return onChange(emptyVal)
    }
    const val = props.type === 'number' ? Number(e.target.value) : e.target.value
    return onChange(val)
  }, [onChange, props.type])

  return (
    <>
      <input
        {...props}
        value={value ?? ''}
        onChange={handleChange}
        className={cx(styles.input, error && styles.error, props.className)}
        ref={inputRef}
      />
    </>
  )
}

Input.defaultProps = {
  rules: {},
}

export default Input
