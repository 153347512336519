import cx from 'classnames'
import type { ControlProps } from 'react-select'
import { components } from 'react-select'

import classNames from './styles.module.scss'


export default function DropdownIndicator(props: ControlProps): JSX.Element {
  return (
    <components.DropdownIndicator {...props}>
      <button
        tabIndex={-1}
        className={classNames.dropdownIndicatorButton}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
      >
        <span className={cx(classNames.dropdownIndicator, props.selectProps.menuIsOpen && classNames.active)}/>
      </button>
    </components.DropdownIndicator>
  )
}
