import { emptyApplicationsArray } from '@common/constants'
import { useAppSelector, useTranslate } from '@common/hooks'
import { getEmptyAppListTitle } from '@common/utils/getSortOrderTitle'

import classNames from './styles.module.scss'


interface IProps {
  reducer: AppListReducer,
}

export default function EmptyAppList({
  reducer,
}: IProps): JSX.Element | null {
  const applicationList = useAppSelector(store => store[reducer]?.data?.items || emptyApplicationsArray)
  const isLoading = useAppSelector(store => store[reducer]?.isLoading)
  const { translate } = useTranslate()

  if(applicationList.length > 0 || isLoading) { return null }

  return (
    <div className={classNames.wrapper} data-test="empty-app-list">
      {translate(getEmptyAppListTitle(reducer))}
    </div>
  )
}
