import { useOutsideClick, useTranslate } from '@common/hooks'
import cx from 'classnames'
import { useEffect, useRef, useState } from 'react'

import classNames from './styles.module.scss'
import UserSlots from './UserSlots'


interface IProps {
  slot?: number,
  onClick: (slot: number) => void,
  disabled: boolean,
  className?: string,
}

function SlotSelector({
  slot,
  onClick,
  disabled,
  className,
}: IProps): JSX.Element {
  const { translate, interpolate } = useTranslate()
  const [show, setShow] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  useOutsideClick(ref, () => setShow(false))

  useEffect(() => {
    setShow(false)
  }, [slot])

  return (
    <div className={cx(classNames.wrapper, className)} ref={ref}>
      <h3
        className={cx(classNames.slotTitle, show && classNames.active, disabled && classNames.disabled)}
        onClick={() => !disabled && setShow(show => !show)} data-test="slot-header"
      >
        {interpolate(translate('Slot %s'), [slot || ''])}
        <i className={classNames.indicatorIcon} data-test="slot-indicator"/>
      </h3>
      {show && (
        <div className={classNames.selectorList} data-test="selector-list">
          <UserSlots onClick={onClick} slotSelected={slot} />
        </div>
      )}
    </div>
  )
}

SlotSelector.defaultProps = {
  slot: undefined,
  className: undefined,
}

export default SlotSelector
