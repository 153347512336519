import type enTranslation from '@common/i18n/translations/en.json'

const titles: {[key in AppListReducer]: keyof typeof enTranslation } = {
  topEarning: 'Top earning apps',
  trendingApps: 'Trending apps',
  newAndPopular: 'Popular new apps',
  recentlyUpdated: 'Recently updated apps',
  recentlyUsed: 'Recently used apps',
  searchApps: 'results',
  hackathonWinners: '2024 Hackathon Winners',
}

export default function getSortOrderTitle(sortOrder: AppListReducer): keyof typeof enTranslation {
  return titles[sortOrder]
}

const emptyAppListTitles: {[key in AppListReducer]: keyof typeof enTranslation } = {
  topEarning: 'No top earning apps',
  trendingApps: 'No trending apps',
  newAndPopular: 'No popular new apps',
  recentlyUpdated: 'No recently updated apps',
  recentlyUsed: 'No recently used apps',
  searchApps: 'No results',
  hackathonWinners: 'No 2024 Hackathon Winners',
}

export function getEmptyAppListTitle(sortOrder: AppListReducer): keyof typeof enTranslation {
  return emptyAppListTitles[sortOrder]
}
