import cx from 'classnames'
import { components } from 'react-select'

import classNames from './styles.module.scss'
import type { SelectProps } from './types'


export default function Control({ children, isFocused, ...props }: SelectProps): JSX.Element {
  return (
    <components.Control
      {...props}
      isFocused={isFocused}
      className={cx(
        classNames.control,
        isFocused && classNames.focused,
        props.selectProps.menuIsOpen && classNames.active,
        props.selectProps.classNameControl,
      )}
      data-test={props.selectProps['data-test'] + '-control'}
    >
      {children}
    </components.Control>
  )
}
