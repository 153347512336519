import type { SettingFieldType } from '@common/forms/forms.types'
import { useIframe, useSession } from '@common/hooks'
import generateSelectOptions from '@common/utils/generateSelectOptions'
import type { InputHTMLAttributes, KeyboardEvent } from 'react'
import { useCallback } from 'react'
import type { FieldValues, Path, RegisterOptions } from 'react-hook-form'
import { useController, useFormContext } from 'react-hook-form'

import FieldLayout from './FieldLayout'
import Checkbox from './fields/Checkbox'
import Input from './fields/Input'
import Select from './fields/Select'


interface IProps<TFormValues extends FieldValues> {
  name: Path<TFormValues>
  rules?: RegisterOptions,
  type: SettingFieldType,
  options?: string[],
  error?: string,
  label?: string,
  description?: string | string[],
  layoutClassName?: string,
}

SettingsField.defaultProps = {
  layoutClassName: '',
}

function SettingsField<TFormValues extends Record<string, unknown>>({ name, rules, type, options, error, label, layoutClassName, description, ...props }: IProps<TFormValues> & InputHTMLAttributes<HTMLInputElement>): JSX.Element | null {
  const { control } = useFormContext()
  const { isAuthorizedUser } = useSession()
  const { showLoginOverlay } = useIframe(null)
  const {
    field: { ref, ...fieldsProps },
  } = useController({
    name,
    control,
    rules,
  })
  const handleKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    // Prevent 'e' from being entered in number inputs
    if(type === 'number' && e.keyCode === 69) {
      e.preventDefault()
    }
  }, [type])

  let Component
  let typeInput
  if(type === 'boolean' || type === 'permission') {
    Component = Checkbox
  } else if(type === 'string' && options) {
    Component = Select
  } else if(type === 'string' && options === undefined) {
    Component = Input
    typeInput = 'text'
  } else if(type === 'number') {
    Component = Input
    typeInput = type
  }
  if(!Component) { return null }
  return <FieldLayout
    required={type !== 'boolean' && Boolean(rules?.required)}
    error={error}
    label={label}
    description={description}
    type={type}
    className={layoutClassName}
  >
    <Component
      {...props}
      {...fieldsProps}
      inputRef={ref}
      type={typeInput}
      options={options ? generateSelectOptions(options) : undefined}
      error={Boolean(error)}
      onKeyDown={handleKeyDown}
      onChange={(...args: any[]) => {
        if(!isAuthorizedUser) {
          showLoginOverlay()
          return
        }
        fieldsProps.onChange(...args)
      }}
    />
  </FieldLayout>
}

SettingsField.defaultProps = {
  options: undefined,
  rules: {},
  error: undefined,
  label: undefined,
  description: undefined,
}

export default SettingsField
