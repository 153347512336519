import type { MenuListProps } from 'react-select'
import { components } from 'react-select'

import classNames from './styles.module.scss'


export default function MenuList({ children, ...props }: MenuListProps): JSX.Element {
  return (
    <components.MenuList {...props} className={classNames.menuList}>
      {children}
    </components.MenuList>
  )
}
