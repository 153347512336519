import cx from 'classnames'
import type { SingleValueProps } from 'react-select'
import { components } from 'react-select'

import classNames from './styles.module.scss'
import type { SelectProps } from './types'


export default function SingleValue({ children, ...props }: SingleValueProps & SelectProps): JSX.Element {
  return (
    <components.SingleValue
      {...props}
      className={cx(
        classNames.singleValue,
        props.selectProps.classNameSingleValue
      )}
    >
      {children}
    </components.SingleValue>
  )
}
