import { useTranslate } from '@common/hooks'
import cx from 'classnames'

import classNames from './styles.module.scss'


interface IProps {
  className?: string,
  application: IApplication,
  showReason?: boolean,
}

ApplicationStatus.defaultProps = {
  className: '',
  showReason: false,
}

const disabledReasons: Record<string, boolean> = {
  'user requested': true,
  idle: true,
}

export default function ApplicationStatus({
  application,
  className,
  showReason,
}: IProps): JSX.Element | null {
  const { translate } = useTranslate()
  const { isActive, isInstalled, reason } = application
  const isCrashed = isInstalled && !isActive && Boolean(reason) && !disabledReasons[reason as string]

  if(!isInstalled) {
    return null
  }

  return (
    <span className={cx(classNames.wrapper, className)}>
      <span className={cx(classNames.installed, isCrashed && classNames.crashed)} data-test="app-status">
        {isCrashed
          ? translate('Crashed')
          : isActive
            ? translate('Installed')
            : translate('Stopped')
        }
      </span>
      {showReason && isCrashed && <label className={classNames.reason}>{application.reason}</label>}
    </span>
  )
}
