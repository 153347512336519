import type { SettingFieldType } from '@common/forms/forms.types'
import cx from 'classnames'

import styles from './styles.module.scss'


interface IProps {
  children: any,
  label?: string,
  description?: string | string[],
  error?: string,
  required: boolean,
  type?: SettingFieldType,
  className?: string,
}

FieldLayout.defaultProps = {
  description: undefined,
  label: undefined,
  error: undefined,
  type: undefined,
  className: '',
}

export default function FieldLayout({
  label,
  description,
  className,
  children,
  error,
  required,
  type,
}: IProps): JSX.Element {
  return (
    <>
      <label className={cx(styles.row, type !== 'permission' ? styles.settingsRow : styles.permissionRow, className)}>
        <div className={styles.text}>
          <h4
            className={cx(styles.title, type === 'permission' && styles.permission)}
            data-test={type === 'permission' ? 'permission-label' : 'setting-label'}
          >
            {label}
            {required && <span data-test="required-star" className={styles.required}>*</span>}
          </h4>
          {Boolean(description) && Array.isArray(description) && description.map((lineItem, index) => (
            <p key={index} className={styles.description} data-test={type === 'permission' ? 'permission-description' : 'setting-description'}>
              {lineItem}
            </p>
          )
          )}
          {Boolean(description) && !Array.isArray(description) && (
            <p className={styles.description} data-test={type === 'permission' ? 'permission-description' : 'setting-description'}>
              {description}
            </p>
          )}
        </div>
        <div className={styles.wrapper}>
          {children}
          {error && <div className={styles.errorMessage}>{error}</div>}
        </div>
      </label>
    </>
  )
}
