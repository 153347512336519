import type { PlaceholderProps } from 'react-select'
import { components } from 'react-select'

import classNames from './styles.module.scss'


export default function Placeholder({ children, ...props }: PlaceholderProps): JSX.Element {
  return (
    <components.Placeholder {...props} className={classNames.placeholder}>
      {children}
    </components.Placeholder>
  )
}
