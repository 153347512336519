import cx from 'classnames'
import { components } from 'react-select'

import classNames from './styles.module.scss'
import type { SelectProps } from './types'


export default function IndicatorsContainer(props: SelectProps): JSX.Element {
  return (
    <components.IndicatorsContainer {...props} className={cx(
      classNames.indicatorsContainer,
      props.selectProps.classNameIndicator
    )} />
  )
}
