import cx from 'classnames'
import type { OptionProps } from 'react-select'
import { components } from 'react-select'

import classNames from './styles.module.scss'
import type { SelectProps } from './types'


export default function Option({ selectProps, data, isSelected, isFocused, ...props }: OptionProps & SelectProps): JSX.Element {
  return (
    <components.Option
      {...props}
      selectProps={selectProps}
      data={data}
      isFocused={isFocused}
      isSelected={isSelected}
      className={
        cx(
          classNames.option,
          selectProps.classNameOption,
          (isSelected || isFocused) && classNames.selected,
        )
      }
    />
  )
}
